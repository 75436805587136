$(document).ready(function () {
  $(".location i").addClass("brinca-brinca");
  $("body").css("visibility", "visible");
  $("body").addClass("fadeTofade");
  var domain = "https://loveland.3margaritas.com/";
  var home_ = domain;
  var about_ = domain + "about";
  var menu_ = domain + "menu";
  var specials_ = domain + "specials";
  var catering_ = domain + "catering";
  var parties_ = domain + "parties";
  var jobs_ = domain + "jobs";
  var accessibility_ = domain + "accessibility";
  const pages = [
    { page: "home", file: home_ },
    { page: "about", file: about_ },
    { page: "menu", file: menu_ },
    { page: "specials", file: specials_ },
    { page: "catering", file: catering_ },
    { page: "parties", file: parties_ },
    { page: "jobs", file: jobs_ },
    { page: "accessibility", file: accessibility_ },
  ];

  $(
    ".home, .about, .menu, .specials, .catering, .parties, .jobs, .accessibility"
  ).each(function () {
    $(this).click(function () {
      var clickedClass = $(this).attr("class").split(" ")[0];
      var pageObj = pages.find(function (page) {
        return page.page === clickedClass;
      });
      if (pageObj) {
        var file = pageObj.file;
        window.location.href = file;
      }
    });
  });

  function agregarClaseNavActivo() {
    var currentPath = window.location.pathname;
    $(".page").each(function () {
      var link = $(this).text().toLowerCase();
      if (currentPath.includes(link)) {
        $(this).addClass("nav_activo");
      }
    });
    if (currentPath.endsWith("/")) {
      $(".home").addClass("nav_activo");
    }
  }
  $(document).ready(function () {
    agregarClaseNavActivo();
  });

  $(window).on("scroll", function () {
    if ($(window).scrollTop() > 150) {
      $("header").addClass("sticky_header");
      $(".up").addClass("show_btn");
      $(".opn-cls span").addClass("achica");
    } else {
      $("header").removeClass("sticky_header");
      $(".up").removeClass("show_btn");
      $(".opn-cls span").removeClass("achica");
    }
  });

  var upUp = $(".up");
  upUp.on("click", function () {
    $(window).scrollTop(0);
  });

  $(".opn-cls-orders").on("click", function (event) {
    event.stopPropagation();
    $(".nav-box").toggleClass("expande");
    $(".opn-cls-orders i").toggleClass("rotate-it");
  });

  $(document).on("click", function (event) {
    if (
      !$(event.target).closest(".nav-box").length &&
      !$(event.target).closest(".opn-cls-orders").length
    ) {
      $(".nav-box").removeClass("expande");
      $(".opn-cls-orders i").removeClass("rotate-it");
    }
  });

  $(".opn-cls").on("click", function () {
    $("nav ul").toggleClass("open--cls");
    $(".opn-cls span").toggleClass("equis");
  });

  $(document).on("click", function (event) {
    if (
      !$(event.target).closest("nav").length &&
      $("nav ul").hasClass("open--cls")
    ) {
      $("nav ul").removeClass("open--cls");
      $(".opn-cls span").removeClass("equis");
    }
  });

  var allowedPaths2 = ["/menu"];
  var currentPath2 = window.location.pathname.replace(".html", "");
  if (allowedPaths2.includes(currentPath2)) {
    $('[data-fancybox="menu_gallery"]').fancybox({
      hash: false,
    });
  }
  var allowedPaths = ["/home", "/about", "/"];
  var currentPath = window.location.pathname.replace(".html", "");

  if (allowedPaths.includes(currentPath)) {
    $('[data-fancybox="gallery"]').fancybox({
      hash: false,
    });

    $(document).ready(function () {
      var $slides, interval, $selectors, $btns, currentIndex, nextIndex;

      var cycle = function (index) {
        var $currentSlide, $nextSlide, $currentSelector, $nextSelector;

        nextIndex = index !== undefined ? index : nextIndex;

        $currentSlide = $($slides.get(currentIndex));
        $currentSelector = $($selectors.get(currentIndex));

        $nextSlide = $($slides.get(nextIndex));
        $nextSelector = $($selectors.get(nextIndex));

        $currentSlide.removeClass("active").css("z-index", "0");

        $nextSlide.addClass("active").css("z-index", "1");

        $currentSelector.removeClass("current");
        $nextSelector.addClass("current");

        currentIndex =
          index !== undefined
            ? nextIndex
            : currentIndex < $slides.length - 1
            ? currentIndex + 1
            : 0;

        nextIndex = currentIndex + 1 < $slides.length ? currentIndex + 1 : 0;
      };

      $(function () {
        currentIndex = 0;
        nextIndex = 1;

        $slides = $(".slide");
        $selectors = $(".selector");
        $btns = $(".btn");

        $slides.first().addClass("active");
        $selectors.first().addClass("current");

        interval = window.setInterval(cycle, 4000);

        $selectors.on("click", function (e) {
          var target = $selectors.index(e.target);
          if (target !== currentIndex) {
            window.clearInterval(interval);
            cycle(target);
            interval = window.setInterval(cycle, 4000);
          }
        });

        $btns.on("click", function (e) {
          window.clearInterval(interval);
          if ($(e.target).hasClass("prev")) {
            var target =
              currentIndex > 0 ? currentIndex - 1 : $slides.length - 1;
            cycle(target);
          } else if ($(e.target).hasClass("next")) {
            cycle();
          }
          interval = window.setInterval(cycle, 4000);
        });
      });

      var menu = [];
      $(".swiper-slide").each(function (index) {
        menu.push($(this).find(".slide-inner").attr("data-text"));
      });

      var interleaveOffset = 0.5;
      var swiperOptions = {
        loop: true,
        speed: 1000,
        parallax: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        loopAdditionalSlides: 0,
        watchSlidesProgress: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          progress: function () {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              var slideProgress = swiper.slides[i].progress;
              var innerOffset = swiper.width * interleaveOffset;
              var innerTranslate = slideProgress * innerOffset;
              swiper.slides[i].querySelector(".slide-inner").style.transform =
                "translate3d(" + innerTranslate + "px, 0, 0)";
            }
          },
          touchStart: function () {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = "";
            }
          },
          setTransition: function (speed) {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = speed + "ms";
              swiper.slides[i].querySelector(".slide-inner").style.transition =
                speed + "ms";
            }
          },
        },
      };

      var swiper = new Swiper(".swiper-container", swiperOptions);

      var sliderBgSetting = $(".slide-bg-image");
      sliderBgSetting.each(function (indx) {
        if ($(this).attr("data-background")) {
          $(this).css(
            "background-image",
            "url(" + $(this).data("background") + ")"
          );
        }
      });
    });
  }

  function menuTabs() {
    var tabs = $(".menu_tabs");
    var menuDivs = $(".mm");
    menuDivs.hide();
    menuDivs.eq(0).show();
    tabs.eq(0).addClass("colorea");
    tabs.each(function (index) {
      $(this).click(function () {
        menuDivs.hide();
        menuDivs.eq(index).show();
        tabs.removeClass("colorea");
        $(this).addClass("colorea");
      });
    });
  }
  menuTabs();

  var allowedPaths = ["/home", "/", "/about"];
  var currentPath = window.location.pathname.replace(".html", "");
  if (allowedPaths.includes(currentPath)) {
    $(document).ready(function () {
      function scrollToVips() {
        var vipsElement = $(".vips");
        if (vipsElement.length > 0) {
          $("html, body").animate(
            {
              scrollTop: vipsElement.offset().top,
            },
            500
          );
        }
      }
      $("[data-scroll-to]").on("click", function () {
        scrollToVips();
      });
    });
  }

  var allowedPaths = ["/home", "/about", "/"];
  var currentPath = window.location.pathname.replace(".html", "");
  if (allowedPaths.includes(currentPath)) {
    $(document).ready(function () {
      const mediaQuery = window.matchMedia("(min-width: 600px)");

      if (mediaQuery.matches) {
        const $fadersLeft = $(".fade-left");
        const $fadersRight = $(".fade-right");

        const appearOptions = {
          threshold: 0,
          rootMargin: "0px 0px -50px 0px",
        };

        const appearOnScroll = new IntersectionObserver(function (entries) {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              $(entry.target).addClass("show");
            } else {
              $(entry.target).removeClass("show");
            }
          });
        }, appearOptions);

        $fadersLeft.each(function () {
          appearOnScroll.observe(this);
        });

        $fadersRight.each(function () {
          appearOnScroll.observe(this);
        });
      } else {
      }
    });
  }

  var allowedPaths = ["/home", "/"];
  var currentPath = window.location.pathname.replace(".html", "");

  setTimeout(function () {
    if (allowedPaths.includes(currentPath)) {
      var modalFondo = document.querySelector(".modal-fondo");
      var bgImage = modalFondo.getAttribute("data-bg");

      if (bgImage) {
        modalFondo.style.backgroundImage = bgImage;
      }

      document.body.classList.add("no-scroll");
      var modal = document.querySelector(".modal");
      modal.classList.add("show_modal");
      modal.classList.add("fadeIn");

      document.querySelectorAll(".btn_modal").forEach(function (button) {
        button.addEventListener("click", function () {
          modal.classList.add("animationless");
          modal.classList.remove("fadeIn");
          document.body.classList.remove("no-scroll");
        });
      });
    }
  }, 1500);

  $(".year").each(function () {
    var currentDate = new Date();
    var ANHO = currentDate.getFullYear();
    $(this).text(ANHO);
  });

  var currentDate = new Date().toISOString();
  var ogUpdatedTimeMeta = $("#ogUpdatedTime");
  if (ogUpdatedTimeMeta.length > 0) {
    ogUpdatedTimeMeta.attr("content", currentDate);
  }

  $(document).ready(function () {
    // Selecciona todas las imágenes en el documento
    $("img").each(function () {
      // Obtén la URL actual de la imagen
      var src = $(this).attr("src");

      // Añade un query string con un timestamp para evitar la caché
      $(this).attr("src", src + "?v=" + new Date().getTime());
    });
  });

  $(document).ready(function () {
    // Selecciona todos los elementos <script> que cargan archivos JavaScript
    $("script[src]").each(function () {
      // Obtén la URL actual del archivo JavaScript
      var src = $(this).attr("src");

      // Añade un query string con un timestamp para evitar la caché
      $(this).attr("src", src.split("?")[0] + "?v=" + new Date().getTime());
    });
  });

  //Location Name
  LOCATION_NAME = "3 Margaritas";
  //Description
  ABOUT_HOME =
    "<span>3 Margaritas restaurant is an example of the immigrant dream, which included financial success as well as a chance to go back home and help those left behind. The nine Morales kids, six girls and three boys, slept on mats on the floor of a two bedroom house at their small ranch. After school they worked in the fields. Today all of them own at least one restaurant. After crossing the border Nicolas Morales ended up in Seattle where his brother Manuel lived.</span><span>The Morales brothers saved all they could knowing they wanted to open their own restaurant. They knew they were now in a new country, and they decided they’d better adapt if they wanted to make it. So Nicolas learned how to cook. They invested all of their savings in their first restaurant, Las Margaritas, a six-table place in Seattle. For six months they couldn’t even pay the rent and had to ask friends to loan them money. Their luck changed when...</span>";
  ABOUT_ABOUT =
    "<span>3 Margaritas restaurant is an example of the immigrant dream, which included financial success as well as a chance to go back home and help those left behind. The nine Morales kids, six girls and three boys, slept on mats on the floor of a two bedroom house at their small ranch. After school they worked in the fields. Today all of them own at least one restaurant. After crossing the border Nicolas Morales ended up in Seattle where his brother Manuel lived.</span><span>The Morales brothers saved all they could knowing they wanted to open their own restaurant. They knew they were now in a new country, and they decided they’d better adapt if they wanted to make it. So Nicolas learned how to cook. They invested all of their savings in their first restaurant, Las Margaritas, a six-table place in Seattle. For six months they couldn’t even pay the rent and had to ask friends to loan them money. Their luck changed when a food critic from Seattle Times paid them a visit and published a review that called Las Margaritas the best Mexican restaurant in the state. The next day, Morales said the food sold out in an hour and they had to close at 7 pm. Nicolas Morales and his brother, Manuel Morales, had 15 restaurants in Washington state before selling them to former employees and starting a new chain in Colorado, 3 Margaritas, with his brother Gustavo Morales.</span><span>Now Manuel, Nicolas and Gustavo Morales own 13 restaurants here, some as partnerships with their employees under the new name 'MARGARITAS'. After living in the United States for 27 years and developing two chains of Mexican restaurants, Nicolas Morales is now a mayor in his hometown Cuautla, in the state of Jalisco, Mexico. His agenda is ambitious: to change the very conditions —lack of jobs, education and opportunity— that made him cross the border to the United States when he was 17.</span>";
  //Address
  ADDRESS = "1417 Cheyenne Ave, Loveland";
  ADDRESS_URL = "https://maps.app.goo.gl/J8Q9NxrfBfmhCBuW8";
  //Email
  EMAIL = "jgalvanramos@hotmail.com";
  //Phone
  PHONE_NUMBER = "(970) 669-4441";
  //Hours & Days
  D1 = "sun-thu";
  H1 = "11:00 a.m. - 9:00 p.m.";
  D2 = "fri-sat";
  H2 = "11:00 a.m. - 10:00 p.m.";
  D3 = "";
  H3 = "";
  D4 = "";
  H4 = "";
  GOOGLE_DESK =
    "https://www.google.com/search?client=opera-gx&q=3+margaritas+loveland&sourceid=opera&ie=UTF-8&oe=UTF-8#lrd=0x8769532139cc32d7:0xfbea8ae53b95c60d,3,,,,";
  GOOGLE_MOB =
    "https://www.google.com/search?client=ms-android-attmexico-mx-rvc3&sca_esv=2c816905e2c07778&sca_upv=1&sxsrf=ACQVn08xjt-GQP65RJZ8riYOoIIyzqqjOQ%3A1712013561584&q=3%20Margaritas%20Loveland&ludocid=18152473965222020621&ibp=gwp%3B0%2C7&lsig=AB86z5UEulfa3DcD-6na4LLngxpg&kgs=6b680b2325d49da7&shndl=-1&source=sh%2Fx%2Floc%2Fact%2Fm4%2F2#wptab=si:AKbGX_oFio40EDpEiLGW5yb14kHbrhj0-kgJPotqLcIs2tFuH-Rf5aO6RCV1eCh_2EThEaR_bfxhMJV_X-aUCKlRcwS4FXpU3DnNTFW6D4hFg57_vec_ZjjV6a3bToW1F8649kJZkDHQxKaI5tRw_p_roSkoN1Hs-Q%3D%3D";
  //Orders Online
  DELIVERY1 =
    "https://www.grubhub.com/restaurant/3-margaritas-loveland-1417-cheyenne-ave-loveland/989991?utm_source=google&utm_medium=organic&utm_campaign=place-action-link";
  DELIVERY2 =
    "https://www.doordash.com/store/3-margaritas-loveland-228158/?cursor=eyJzZWFyY2hfaXRlbV9jYXJvdXNlbF9jdXJzb3IiOnsicXVlcnkiOiIzIG1hcmdhcml0YXMgbG92ZWxhbmQiLCJpdGVtX2lkcyI6W10sInNlYXJjaF90ZXJtIjoidGhyZWUgbWFyZ2FyaXRhIGxvdmVsYW5kIiwidmVydGljYWxfaWQiOi05OTksInZlcnRpY2FsX25hbWUiOiJhbGwifSwic3RvcmVfcHJpbWFyeV92ZXJ0aWNhbF9pZHMiOlsxLDQsMTc3XX0=&pickup=false";
  DELIVERY3 =
    "https://www.ubereats.com/mx/store/3-margaritas-loveland/Ls1ZXR56ViaK0GiavG_y7g?diningMode=DELIVERY&sc=SEARCH_SUGGESTION";
  //Iframe
  IFRAME =
    '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12152.16329545039!2d-105.0511382!3d40.4079464!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8769532139cc32d7%3A0xfbea8ae53b95c60d!2s3%20Margaritas%20Loveland!5e0!3m2!1ses-419!2smx!4v1724780090265!5m2!1ses-419!2smx" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';
  DESC01 = "order your favorite dishes in seconds!";
  DESC02 = "subscribe to <br> our vip club!";
  //Social Media
  FACEBOOK = "https://www.facebook.com/3margaritasloveland";
  INSTAGRAM = "https://www.instagram.com/3margaritasloveland/";
  YELP = "https://www.yelp.com/biz/3-margaritas-loveland-loveland";
  TIKTOK = "https://www.tiktok.com/@3_margaritas";
  //Agency
  RESTAURANTLY = "https://restaurantly.io/";
  //Agency
  $(".agency").on("click", function () {
    window.open(RESTAURANTLY, "_blank");
  });
  //Logo
  $("header img").css("cursor", "pointer");
  $("header img").on("click", function () {
    window.location.href = domain;
  });
  //Location - Name
  $(".location_name").each(function () {
    $(this).text(LOCATION_NAME);
  });
  //Location - Address
  $(".address").each(function () {
    $(this).text(ADDRESS);
  });
  $(".address-link").each(function () {
    $(this).click(function () {
      window.open(ADDRESS_URL, "_blank");
    });
  });
  //About Us
  $(".description_home").each(function () {
    $(this).html(ABOUT_HOME);
  });
  $(".description_about").each(function () {
    $(this).html(ABOUT_ABOUT);
  });
  //Phone
  $(".phone").each(function () {
    $(this)
      .text(PHONE_NUMBER)
      .click(function () {
        window.location.href = "tel:" + PHONE_NUMBER;
      });
  });
  // Email
  $(".email").each(function () {
    $(this)
      .text(EMAIL)
      .click(function () {
        window.location.href = "mailto:" + EMAIL;
      });
  });
  //Hours
  $(".d1").each(function () {
    $(this).text(D1);
  });
  $(".h1").each(function () {
    $(this).text(H1);
  });
  $(".d2").each(function () {
    $(this).text(D2);
  });
  $(".h2").each(function () {
    $(this).text(H2);
  });
  $(".d3").each(function () {
    $(this).text(D3);
  });
  $(".h3").each(function () {
    $(this).text(H3);
  });
  $(".d4").each(function () {
    $(this).text(D4);
  });
  $(".h4").each(function () {
    $(this).text(H4);
  });
  //Iframe
  $(".iframe").each(function () {
    $(this).html(IFRAME);
  });
  //Description Modal
  $(".description-01").each(function () {
    $(this).html(DESC01);
  });
  $(".description-02").each(function () {
    $(this).html(DESC02);
  });
  //Google Reviews
  $(".desk-google").each(function () {
    $(this).click(function () {
      window.open(GOOGLE_DESK, "_blank");
    });
  });
  $(".mob-google").each(function () {
    $(this).click(function () {
      window.open(GOOGLE_MOB, "_blank");
    });
  });
  //3rd Party Vendors
  $(".order-pickup").each(function () {
    $(this).click(function () {
      window.open(PICK_UP, "_blank");
    });
  });
  $(".order-delivery1").each(function () {
    $(this).click(function () {
      window.open(DELIVERY1, "_blank");
    });
  });
  $(".order-delivery2").each(function () {
    $(this).click(function () {
      window.open(DELIVERY2, "_blank");
    });
  });
  $(".order-delivery3").each(function () {
    $(this).click(function () {
      window.open(DELIVERY3, "_blank");
    });
  });
  //Social Media
  $(".fb").each(function () {
    $(this).click(function () {
      window.open(FACEBOOK, "_blank");
    });
  });
  $(".ig").each(function () {
    $(this).click(function () {
      window.open(INSTAGRAM, "_blank");
    });
  });
  $(".yp").each(function () {
    $(this).click(function () {
      window.open(YELP, "_blank");
    });
  });
  $(".tk").each(function () {
    $(this).click(function () {
      window.open(TIKTOK, "_blank");
    });
  });
});
